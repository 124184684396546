/* Toast.css */

.toast {
    position: fixed;
    bottom: 20px;
    left: 20px;
    padding: 15px;
    border-radius: 5px;
    color: #fff;
    z-index: 1000;
    display: flex;
    align-items: center;
}

.toast--success {
    background-color: #4caf50;
}

.toast--error {
    background-color: #f44336;
}

.toast--warn {
    background-color: #ff9800;
}

.toast--info {
    background-color: #2196f3;
}

.toast__close-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 20px;
    margin-left: 15px;
    cursor: pointer;
}
