/* ManagerMenu.css */

.manager-menu {
    background-color: #007bff;
    padding: 10px 0;
    display: flex; /* Added */
    justify-content: space-between; /* Added */
}

.manager-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.menu-left {
    /* No changes needed */
}

.menu-right {
    /* No changes needed */
}

.manager-menu ul li {
    margin: 0 10px;
}

.manager-menu ul li a {
    color: white;
    text-decoration: none;
    font-size: 16px;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.manager-menu ul li a:hover {
    background-color: #0056b3;
}

.menu-button {
    cursor: pointer;
}
