.panel-login {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5; /* Светлый фон для всего окна */
}

.form-container {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Легкая тень для формы */
    text-align: center;
}

.label-login {
    display: block;
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
}

.input-login {
    width: 100%;
    padding: 12px;
    margin-bottom: 24px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-size: 16px;
}

.submit-button-login {
    width: 100%;
    padding: 12px;
    border: none;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    background-color: #4CAF50;
    transition: background-color 0.3s;
}

.submit-button-login:hover {
    background-color: #45a049;
}

.error-message {
    color: red;
    margin-top: 12px;
    font-size: 14px;
}
