/* FactoriesPage.css */

.color-selection {
    display: flex;
    gap: 10px;
    margin: 10px 0;
}

.color-selection--two-rows {
    flex-wrap: wrap;
    max-width: 300px;
}

.color-option {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid transparent;
}

.color-option.selected {
    border-color: #000;
}





.factories-page__container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.factories-page__cluster-controls {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.factories-page__cluster-controls button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.factories-page__cluster-controls button:hover {
    background-color: #0056b3;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
}

.modal-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.modal-create-button {
    padding: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.modal-create-button:hover {
    background-color: #218838;
}

.modal-cancel-button {
    padding: 10px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.modal-cancel-button:hover {
    background-color: #c82333;
}

.factories-page__cluster {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.factories-page__cluster-header {
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
}

.factories-page__cluster-header-title {
    flex-grow: 1;
}

.factories-page__cluster-header-buttons {
    display: flex;
    gap: 10px;
}

.factories-page__edit-cluster-button,
.factories-page__delete-cluster-button,
.factories-page__move-cluster-button {
    padding: 5px;
    cursor: pointer;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 3px;
}

.factories-page__delete-cluster-button {
    background-color: #dc3545;
}

.factories-page__edit-cluster-button:hover {
    background-color: #218838;
}

.factories-page__delete-cluster-button:hover {
    background-color: #c82333;
}

.factories-page__move-cluster-button {
    background-color: #007bff;
}

.factories-page__move-cluster-button:hover {
    background-color: #0056b3;
}

.factories-page__factories-list {
    margin-top: 10px;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    min-height: 50px;
}

.factories-page__factories-table {
    width: 100%;
    border-collapse: collapse;
}

.factories-page__factories-table th, .factories-page__factories-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

.factories-page__factory-row:hover {
    background-color: #f1f1f1;
}

.factories-page__buttons button {
    margin-right: 5px;
    padding: 5px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 3px;
}

.factories-page__buttons button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    color: rgba(1, 1, 1, 0.1)
}

.factories-page__buttons button:hover:not(:disabled) {
    background-color: #0056b3;
}

.factories-page__edit-button {
    background-color: #28a745;
}

button.factories-page__edit-button:hover {
    background-color: #218838;
}

button.factories-page__delete-button {
    margin-left: 10px;
    background-color: #dc3545;
}

.factories-page__delete-button:hover {
    background-color: #c82333;
}


button.create-factory-button {
    margin-top: 10px;
    padding: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
}

button.create-factory-button:hover {
    background-color: #5a6268;
}

select {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    font-size: 16px;
}


button.btn_clsp {
    background-color: gray;
}