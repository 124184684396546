.form-input__form-group {
    margin-bottom: 15px;
    position: relative; /* Оставляем для будущего использования */
    width: 100%; /* Гарантируем, что блок занимает всю ширину */
}

.form-input__input-field {
    width: 100%; /* Инпут должен занимать всю ширину контейнера */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box; /* Учитываем padding и border в ширине */
}

.form-input__input-field.error-border {
    border-color: #dc3545;
}

/* Отображение текста ошибки */
.form-input__error-message {
    color: #dc3545;
    font-size: 14px;
    margin-top: 5px; /* Добавляем отступ сверху */
    display: block; /* Гарантируем, что элемент отображается как блок */
    width: 100%; /* Ошибка занимает всю ширину */
}
