/* SickLeavePage.css */

.sick-leave-page {
    padding: 20px;
}

.sick-leave-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.filter-group {
    display: flex;
    align-items: center;
}

.factory-select, .month-select {
    width: 200px;
    padding: 10px;
    font-size: 16px;
    margin: 0 10px;
}

.arrow-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
}

.arrow-button:hover {
    background-color: #0056b3;
}

.sick-leave-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.sick-leave-table th, .sick-leave-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

.sick-leave-table th {
    background-color: #007bff;
    color: white;
}

.export-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 4px;
}

.export-button:hover {
    background-color: #218838;
}

.warning-message {
    color: red;
    font-size: 16px;
    margin-top: 10px;
}
