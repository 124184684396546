.monthly-reports-page {
    padding: 20px;
    font-family: 'Arial', sans-serif;
    margin: 0 auto;
}

.monthly-reports-page__title {
    text-align: center;
    margin-bottom: 30px;
}

.monthly-reports-page__controls {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 20px;
    align-items: center;
}

.monthly-reports-page__control-group {
    display: flex;
    align-items: center;
}

.monthly-reports-page__arrow-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #007bff;
    transition: color 0.2s ease;
}

.monthly-reports-page__arrow-button:disabled {
    color: #cccccc;
    cursor: not-allowed;
}

.monthly-reports-page__arrow-button:hover:not(:disabled) {
    color: #0056b3;
}

.monthly-reports-page__select {
    width: 250px;
    margin: 0 10px;
}

.monthly-reports-page__month-input {
    width: 150px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
    text-align: center;
    margin: 0 10px;
}

.monthly-reports-page__month-input:disabled {
    background-color: #e9ecef;
    color: #6c757d;
    cursor: not-allowed;
}

.monthly-reports-page__actions {
    display: flex;
    align-items: center;
}

.monthly-reports-page__button {
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    transition: background-color 0.2s ease;
    margin-right: 10px;
    background-color: #28a745;
    color: white;
}

.monthly-reports-page__button--save {
    background-color: #28a745;
    color: white;
}

.monthly-reports-page__button--save:hover {
    background-color: #218838;
}

.monthly-reports-page__button--cancel {
    background-color: #dc3545;
    color: white;
}

.monthly-reports-page__button--cancel:hover {
    background-color: #c82333;
}

.monthly-reports-page__button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.monthly-reports-page__button--back {
    background-color: #007bff;
    color: white;
}

.monthly-reports-page__button--back:hover {
    background-color: #0056b3;
}



.monthly-reports-page__upload-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.monthly-reports-page__button {
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    transition: background-color 0.2s ease;
}

.monthly-reports-page__button--clear {
    background-color: #dc3545;
    color: white;
}

.monthly-reports-page__button--clear:hover {
    background-color: #c82333;
}




.monthly-reports-page__table-container {
    overflow-x: auto;
}

.monthly-reports-page__loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.monthly-reports-page__loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: monthly-reports-page__spin 1s linear infinite;
}

@keyframes monthly-reports-page__spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Styles for the table */
.monthly-reports-page .excel-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    text-align: center;
}

.monthly-reports-page .excel-table th,
.monthly-reports-page .excel-table td {
    padding: 8px;
    border: 1px solid #ddd;
}

.monthly-reports-page .excel-table th {
    background-color: #f2f2f2;
}

.monthly-reports-page .excel-table td {
    cursor: text;
}

.monthly-reports-page .excel-table tr:nth-child(even) td {
    background-color: #fafafa;
}

.monthly-reports-page .excel-table td:focus {
    outline: 2px solid #007bff;
}

/* Additional styles for highlighting */
.monthly-reports-page .faded-zero {
    color: lightgray;
}

.monthly-reports-page .highlight-row {
    font-weight: bold;
    background-color: #e9ecef;
}

.monthly-reports-page .collapse-row td {
    background-color: #f9f9f9;
    cursor: pointer;
}

.monthly-reports-page .collapse-cell {
    text-align: center;
}

.monthly-reports-page .collapse-button {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
    color: gray;
}

.monthly-reports-page .highlight-difference {
    color: lightgray;
    font-size: 10px;
}

.monthly-reports-page .highlight-difference-minus {
    color: red;
    font-size: 12px;
}

.monthly-reports-page .highlight-difference-plus {
    color: blue;
    font-size: 12px;
}

.monthly-reports-page .highlight-difference-plus::before {
    content: '+';
}

/* Styles for the upload button */
.monthly-reports-page .upload-button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.monthly-reports-page .upload-button:hover {
    background-color: #45a049;
}

.monthly-reports-page .upload-timesheet-button {
    padding: 0px 10px;
    background-color: #28a745;
    color: white;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.monthly-reports-page .upload-timesheet-button:hover {
    background-color: #218838;
}

.monthly-reports-page .factory-name-cell {
    display: flex;
    align-items: center;
}

.monthly-reports-page .no-timesheet-cell {
    text-align: center;
    /* color: red; */
}

.monthly-reports-page .remove-row-button {
    margin-left: 5px;
}

.monthly-reports-page .view-timesheet-icon {
    margin-left: 8px;
    color: #999;
    cursor: pointer;
    font-size: 16px;
}

.monthly-reports-page .view-timesheet-icon:hover {
    color: #555;
}
