.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
}

.create-edit-factory__form-group {
    margin-bottom: 15px;
}

.create-edit-factory__form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.create-edit-factory__input-field {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
}

.create-edit-factory__btn-save,
.create-edit-factory__btn-cancel {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    width: 48%;
    box-sizing: border-box;
}

.create-edit-factory__btn-save {
    background-color: #28a745;
    color: white;
}

.create-edit-factory__btn-save:hover {
    background-color: #218838;
}

.create-edit-factory__btn-cancel {
    background-color: #dc3545;
    color: white;
}

.create-edit-factory__btn-cancel:hover {
    background-color: #c82333;
}
