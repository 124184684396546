.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 400px;
    position: relative;
}

.create-edit-master__form-group {
    margin-bottom: 15px;
}

.create-edit-master__form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.create-edit-master__input-field {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.create-edit-master__input-field.error-border {
    border-color: #dc3545;
}

.create-edit-master__error-message {
    color: #dc3545;
    font-size: 14px;
}

.create-edit-master__modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.create-edit-master__btn-save,
.create-edit-master__btn-cancel {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.create-edit-master__btn-save {
    background-color: #28a745;
    color: white;
}

.create-edit-master__btn-save:hover {
    background-color: #218838;
}

.create-edit-master__btn-cancel {
    background-color: #dc3545;
    color: white;
}

.create-edit-master__btn-cancel:hover {
    background-color: #c82333;
}

.create-edit-master__btn-generate {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 8px 12px;
    margin-top: 5px;
}

.create-edit-master__btn-generate:hover {
    background-color: #0056b3;
}



/* Обёртка для инпута с иконкой */
.unique-link-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

/* Стили для иконки обновления */
.unique-link-icon {
    position: absolute;
    right: 10px;
    cursor: pointer;
    font-size: 18px;
    color: #007bff;
}

.unique-link-icon:hover {
    color: #0056b3;
}

/* Инпут не изменяется, за исключением того, что добавляется место для иконки */
.create-edit-master__input-field {
    width: 100%;
    padding-right: 40px; /* Оставляем место для иконки */
}

.deleted_div {
    padding: 10px 10px 20px 10px;
    text-align: center;
}


.deletedInput {
    max-width: 10px ;
}

.deletedLabel{
    max-width: 10px ;
}