/* Основные стили */
.report-form-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.report-header {
    text-align: center;
    margin-bottom: 20px;
}

.report-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-group {
    display: flex;
    flex-direction: column;
}

label {
    font-weight: bold;
    margin-bottom: 5px;
}

input, textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
}

.submit-button, .back-button, .cancel-button, .edit-button {
    padding: 12px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
    transition: background-color 0.3s;
}

.submit-button {
    background-color: #4CAF50;
}

.back-button {
    margin-top: 15px;
    background-color: #f44336;
}

.edit-button {
    background-color: cadetblue;
}

.back-button:hover {
    background-color: #e53935;
}

.cancel-button {
    background-color: #dc3545;
    color: white;
    margin-left: 10px;
}


/* Стили для выбора завода */
.factory-select-container {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
}

.factory-list {
    list-style-type: none;
    padding: 0;
}

.factory-list li {
    margin-bottom: 10px;
}

.factory-select-button {
    min-width: 200px;
    padding: 15px;
    background-color: cadetblue;
    color: white;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.factory-select-button:hover {
    background-color: #1976D2;
}


/* Адаптивность */
@media (max-width: 600px) {
    .report-form-container, .factory-select-container {
        padding: 10px;
    }

    .submit-button, .cancel-button, .back-button, .factory-select-button {
        padding: 10px;
        font-size: 14px;
    }
}



/* Стили для модального окна подтверждения */
.confirmation-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 400px;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-align: center;
}

.confirmation-modal p {
    font-size: 18px;
    margin-bottom: 20px;
}

.confirmation-modal button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin: 5px;
    transition: background-color 0.3s;
}

.confirm-button {
    background-color: #4CAF50;
    color: white;
}

.cancel-button {
    background-color: #dc3545;
    color: white;
}

/* Фон для затемнения экрана при показе модального окна */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

pre {
    font-size: 16px;
    background-color: white;
    width: 100%;
    min-height: 20px;
    white-space: pre-line;

}

.report-link-button {
    display: inline-block;
    background-color: transparent; /* Прозрачный фон */
    color: #555; /* Серый цвет текста */
    font-size: 14px; /* Уменьшенный шрифт */
    padding: 8px 12px; /* Добавляем отступы для удобного нажатия */
    margin: 0 5px; /* Небольшой отступ между кнопками */
    border: 1px solid #ccc; /* Легкая рамка */
    border-radius: 4px; /* Скругленные углы */
    text-align: center;
    text-decoration: none; /* Убираем подчеркивание */
    cursor: pointer; /* Курсор при наведении */
    transition: background-color 0.3s, color 0.3s; /* Плавный переход эффектов */
}

.report-link-button:hover {
    background-color: #f0f0f0; /* Легкий акцент при наведении */
    color: #333; /* Более темный текст */
}

.report-link-button:active {
    background-color: #e0e0e0; /* Нажатие более заметное */
    color: #111; /* Более насыщенный текст */
}

/* Адаптивный дизайн для мобильных устройств */
@media (max-width: 600px) {
    .report-link-button {
        font-size: 12px; /* Еще немного уменьшаем текст */
        padding: 6px 10px; /* Уменьшаем отступы */
    }
}

.back-to-current-button {
    display: inline-block;
    background-color: #1976D2; /* Яркий синий цвет */
    color: white; /* Белый текст */
    font-size: 16px; /* Средний размер шрифта */
    padding: 10px 20px; /* Достаточные отступы для удобства нажатия */
    margin: 10px 0; /* Отступы сверху и снизу */
    border: none; /* Убираем рамку */
    border-radius: 6px; /* Скругленные углы */
    cursor: pointer; /* Курсор при наведении */
    text-align: center;
    transition: background-color 0.3s, transform 0.2s; /* Плавные эффекты */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Легкая тень */
}

.back-to-current-button:hover {
    background-color: #1565C0; /* Более насыщенный синий при наведении */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Усиливаем тень */
}

.back-to-current-button:active {
    background-color: #0D47A1; /* Темный синий при нажатии */
    transform: scale(0.95); /* Легкое сжатие для эффекта нажатия */
}


.report-link-button.active {
    font-weight: bold; /* Жирный шрифт */
    border: 1px solid #1565C0; /* Подчеркнуть активное состояние */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Добавить тень */
    transition: background-color 0.3s, box-shadow 0.3s; /* Плавный переход для стиля */
}

.report-link-button.active:hover {
    background-color: #1565C0; /* Более насыщенный синий при наведении */
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2); /* Увеличенная тень при наведении */
}
