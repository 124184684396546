/*.daily-reports__page-container {
    padding: 0;
    margin: 0 auto;
    background-color: #f9f9f9;
}*/
.daily-reports__page-container {
    height: 90vh;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    background-color: #f9f9f9;
}

.content-container {
    flex: 1;
    display: flex;
    flex-direction: column;
}


/*.daily-reports__filter-container {
    position: sticky;
    top: 0;
    background-color: #f9f9f9;
    z-index: 10;
    padding: 20px;
}*/
.daily-reports__filter-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px; /* Adjust padding as needed */
    background-color: #f9f9f9;
    position: sticky;
    top: 0;
    z-index: 10;
    /* Add a bottom border for visual separation */
    border-bottom: 1px solid #ccc;
}


/* Контейнер для таблицы с прокруткой */
.table-container {
    max-height: calc(100vh - 140px); /* Вычитаем высоту меню и панели управления */
    overflow-y: auto;
}

/* Закрепляем шапку таблицы под панелью управления */
.reports-table thead th {
    position: sticky;
    top: 0px; /* Высота меню и панели управления (настроить под ваш размер) */
    background-color: #007bff;
    z-index: 5;
}

/* Устанавливаем фон для заголовков таблицы */
.reports-table th {
    background-color: #007bff;
    color: white;
    font-weight: bold;
    text-align: center;
    padding: 10px;
}
















.page-title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
}

.factory-select {
    width: 400px;
}

.filter-group {
/*
    width: 40%;
*/
}

.date-controls {
    display: flex;
    align-items: center;
}

.date-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 4px;
    margin: 0 10px;
}

.date-button:hover {
    background-color: #0056b3;
}

.date-input {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.export-controls {
    display: flex;
    align-items: center;
}

.export-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    border-radius: 4px;
}

.export-button:hover {
    background-color: #218838;
}

/* Стили для кнопки в состоянии disabled */
.export-button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed; /* Убираем возможность клика */
    opacity: 0.6; /* Делаем кнопку визуально более тусклой */
}

.export-button .excel-icon {
    margin-right: 8px;
    font-size: 20px;
}

.reports-table {
    width: 100%;
    border-collapse: collapse;
}

.reports-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    white-space: normal;
}


.daily-reports__totals-row {
    font-weight: bold;
    background-color: #f1f1f1;
}

@media (max-width: 768px) {
    .daily-reports__filter-container {
        flex-direction: column;
    }

    .filter-group,
    .date-controls,
    .export-controls {
        width: 100%;
        margin-bottom: 10px;
    }

    .date-controls {
        justify-content: center;
    }
}

.td_factory_name{
    font-weight: 700;
    font-size: large;
}

.td_master_fio{
    padding-top:4px;
}

.td_master_phone{
    font-size: small;
}

th.td_num {
    font-size: small;
}

td.td_plan {
    width: 30px;
    max-width: 30px;
    text-align: center;
    padding: 0;
}

td.td_idx, th.td_idx {
    width: 20px;
    max-width: 20px;
    text-align: center;
    padding: 0;
}

td.td_num {
    max-width: 20px;
    text-align: center;
}

td.td_factory {
    text-align: center;
}


th.td_table {
    text-align: right;
    padding-right: 5%;
}

td.td_table {
    padding: 0;
    margin: 0;
    border: none;
}

.td_text {
    font-size: small;
}

table.td_table {
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    font-size: small;
    border-top: 1px solid #ddd;
}

table.td_table tr {
}

table.td_table tr td{
    border: none;
    border-bottom: 1px solid #ddd;
    padding: 0 0 0 10px;
    margin: 0;
}
table.td_table tr th{
    padding: 0px;
    margin: 0;
    background: none;
    color: black;
    border: none;
    border-bottom: 1px solid #ddd;
    border-left: 1px solid #ddd;
    width: 30%;
}

.report-not-provided {
    background-color: #ffe0e0; /* Светло-красная подсветка */
}

.td_report_not_provided {
    text-align: center;
    color: red;
    font-weight: bold;
}

.hide-no-reports-checkbox {
    text-align: center;
}


.td_updated_at {
  color: #888; /* Светло-серый цвет для ненавязчивого отображения */
  font-size: 0.85em; /* Немного уменьшенный размер шрифта */
  margin-top: 25px; /* Отступ сверху для отделения от основной информации */
  display: block; /* Сделать элемент блочным, чтобы дата располагалась отдельно */

}